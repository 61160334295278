import Navbar from 'components/Navbars/AdminNavbar';
import Sidebar from 'components/Sidebar/Sidebar';
import React from 'react';
import { publicPage } from '../menu';

const sidebars = [
	{ path: publicPage.login.path, element: null, exact: true },
	{ path: publicPage.lost_password.path, element: null, exact: true}, 
	{ path: publicPage.reset_password.path, element: null, exact: true}, 
	{
		path: `*`,
		element: <Sidebar />,
	},
];

export default sidebars;
