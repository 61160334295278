import { useRef, useEffect, useState } from "react";

function useDocumentTitle(title, prevailOnUnmount = false) {
  const defaultTitle = useRef(document.title);
  const [pageTitle, setPageTitle] = useState(title);

  useEffect(() => {
    document.title = title ? title : process.env.REACT_APP_PROJECT_NAME;
    setPageTitle(title);
  }, [title]);

  useEffect(
    () => () => {
      if (!prevailOnUnmount) {
        document.title = defaultTitle.current;
      }
    },
    []
  );

  return { pageTitle };
}

export default useDocumentTitle;
