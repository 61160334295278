import { createContext, useEffect, useState } from "react";
import { authProvider } from "../providers/AuthProvider";

const userChannel = new BroadcastChannel("user");

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  let userFromStorage = JSON.parse(localStorage.getItem("user"));
  let [user, setUser] = useState(userFromStorage ? userFromStorage : null);

  useEffect(() => {
    if (user) {
      // Handle token expiration
      console.log('TOKEN CHECK')
      if (Date.now() >= user.exp * 1000) {
        console.log('TOKEN EXPIRED')
        signout();
      }
    }
    userChannel.onmessage = ({ data }) => {
      if (data.payload.type === "SIGN_OUT") {
		window.location.reload();
      }
      if (data.payload.type === "SIGN_IN") {
        window.location.reload();
      }
    };
  }, []);

  let signin = (newUser, jwt, callback) => {
    return authProvider.signin(() => {
      newUser["jwt"] = jwt;
      setUser(newUser);
      localStorage.setItem("user", JSON.stringify(newUser));
      userChannel.postMessage({
        userId: "", // If the user opened your app in multi-tabs and signed-in with multi accounts, you need to put the userId here to identify which account has signed out exactly
        payload: {
          user: newUser,
          type: "SIGN_IN",
        },
      });
    });
  };

  let edituser = (newUser, jwt, callback) => {
    return authProvider.edituser(() => {
      newUser["jwt"] = jwt;
      setUser(newUser);
      localStorage.setItem("user", JSON.stringify(newUser));
    });
  };

  let signup = (newUser, callback) => {
    return authProvider.signup(() => {
      setUser(newUser);
      localStorage.setItem("user", newUser);
    });
  };

  let signout = (callback) => {
    return authProvider.signout(() => {
      if (userChannel) {
        userChannel.postMessage({
          userId: "", // If the user opened your app in multi-tabs and signed-in with multi accounts, you need to put the userId here to identify which account has signed out exactly
          payload: {
            type: "SIGN_OUT",
          },
        });
      }
      setUser(null);
      localStorage.removeItem("user");
	  localStorage.removeItem("_expiredTime")
    });
  };

  let value = { user, signin, signup, signout, edituser };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContext;
