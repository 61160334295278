export const protectedPage = {
  dashboard: {
    id: "dashboard",
    text: "Dashboard",
    path: "/",
    icon: "Dashboard",
    subMenu: null,
  },
  patients: {
    id: "patients",
    text: "Pacienti | Liečba",
    path: "/pacienti",
    icon: "PeopleFill",
    subMenu: {
      library: {
        id: "patients_list",
        text: "Zoznam pacientov",
        path: "/pacienti",
        icon: "people",
        subMenu: null,
      },
      add: {
        id: "addPatient",
        text: "Pridať pacienta",
        path: "/pridat-pacienta",
        hide: true,
      },
      edit: {
        id: "editclientID",
        text: "editclientID",
        path: "/editovanie-pacienta/:id",
        hide: true,
      },
      patientMedicine: {
        id: "patientMedicine",
        text: "patientMedicine",
        path: "/lieky-pacienta/:idPatient",
        hide: true,
      },
      patientMedicineExamination: {
        id: "patientMedicineExamination",
        text: "patientMedicineExamination",
        path: "/frekvencia-vysetreni-pacienta/:idPatient/:idMedicine",
        hide: true,
      },
      detail: {
        id: "clientID",
        text: "clientID",
        path: "/pacient/:id",
        hide: true,
      },
      add_notification: {
        id: "addnotification",
        text: "notification",
        path: "/pridat-notifikaciu/:id",
        hide: true,
      },
      edit_notification: {
        id: "editnotification",
        text: "notification",
        path: "/notifikacia/:id",
        hide: true,
      },
    },
  },

  drugs: {
    id: "drugs",
    text: "Lieky",
    path: "/lieky",
    icon: "PeopleFill",
    subMenu: {
      library: {
        id: "drugs_list",
        text: "Zoznam liekov",
        path: "/lieky",
        icon: "people",
        subMenu: null,
      },
      add: {
        id: "addDrug",
        text: "Pridať liek",
        path: "/lieky/pridat-liek",
        hide: true,
      },
      edit: {
        id: "drugID",
        text: "drugID",
        path: "/liek/:id",
        hide: true,
      },
    },
  },
  examinations_types: {
    id: "examinations_types",
    text: "Typy vyšetrení",
    path: "/typy-vysetreni",
    icon: "PeopleFill",
    subMenu: {
      library: {
        id: "examinations_types_list",
        text: "Zoznam typov vyšetrení",
        path: "/typy-vysetreni",
        icon: "people",
        subMenu: null,
      },
      add: {
        id: "addExaminationType",
        text: "Pridať typ vyšetrenia",
        path: "/typy-vysetreni/pridat-typ-vysetrenia",
        hide: true,
      },
      edit: {
        id: "examinationTypeID",
        text: "examinationTypeID",
        path: "/editovanie-typ-vysetrenia/:id",
        hide: true,
      },
    },
  },
  insurances: {
    id: "insurances",
    text: "Poisťovne",
    path: "/poistovne",
    icon: "PeopleFill",
    subMenu: {
      library: {
        id: "insurances_list",
        text: "Zoznam pacientov",
        path: "/poistovne",
        icon: "people",
        subMenu: null,
      },
      add: {
        id: "addInsurance",
        text: "Pridať poisťovnu",
        path: "/pridat-poistovnu",
        hide: true,
      },
      edit: {
        id: "insuranceID",
        text: "insuranceID",
        path: "/poistovna/:id",
        hide: true,
      },
    },
  },
  personal: {
    id: "personal",
    text: "Personál",
    path: "/personal",
    icon: "personal",
    subMenu: {
      library: {
        id: "personal_list",
        text: "Personál",
        path: "/personal",
        icon: "people",
        subMenu: null,
      },
      add: {
        id: "addPersonal",
        text: "Pridať personal",
        path: "/personal/pridat-personal",
        hide: true,
      },
      edit: {
        id: "personalID",
        text: "personalID",
        path: "/uprava-personal/:id",
        hide: true,
      },
      detail: {
        id: "personaldetailID",
        text: "personaldetailID",
        path: "/personal/:id",
        hide: true,
      },
    },
  },
  workplaces: {
    id: "workplaces",
    text: "Pracoviská",
    path: "/pracoviska",
    subMenu: {
      library: {
        id: "workplace_list",
        text: "Pracoviská",
        path: "/pracoviska",
        icon: "people",
        subMenu: null,
      },
      add: {
        id: "addWorkplace",
        text: "Pridať pracovisko",
        path: "/pracovisko/pridat-pracovisko",
        hide: true,
      },
      edit: {
        id: "workplaceID",
        text: "workplaceID",
        path: "/pracovisko/:id",
        hide: true,
      },
    },
  },
  //   logs: {
  //     id: "settings",
  //     text: "Logy",
  //     path: "/logy",
  //     icon: "Settings",
  //     subMenu: {
  //       library: {
  //         id: "logs_list",
  //         text: "Zoznam logov",
  //         path: "/logy",
  //         icon: "people",
  //         subMenu: null,
  //       },
  //       detail: {
  //         id: "logID",
  //         text: "logID",
  //         path: "/log/:id",
  //         hide: true,
  //       },
  //     },
  //   },
  notifications: {
    id: "notifications",
    text: "Úlohy",
    path: "/ulohy",
    icon: "Notification",
    hide: true,
  },
  settings: {
    id: "settings",
    text: "Nastavenia",
    path: "/nastavenia",
    icon: "Settings",
  },
};

export const adminPage = {
  dashboard: {
    id: "dashboard",
    text: "Dashboard",
    path: "/",
    icon: "Dashboard",
    subMenu: null,
  },
  patients: {
    id: "patients",
    text: "Pacienti | Liečba",
    path: "/pacienti",
    icon: "PeopleFill",
    subMenu: {
      library: {
        id: "patients_list",
        text: "Zoznam pacientov",
        path: "/pacienti",
        icon: "people",
        subMenu: null,
      },
      add: {
        id: "addPatient",
        text: "Pridať pacienta",
        path: "/pridat-pacienta",
        hide: true,
      },
      edit: {
        id: "editclientID",
        text: "editclientID",
        path: "/editovanie-pacienta/:id",
        hide: true,
      },
      patientMedicine: {
        id: "patientMedicine",
        text: "patientMedicine",
        path: "/lieky-pacienta/:idPatient",
        hide: true,
      },
      patientMedicineExamination: {
        id: "patientMedicineExamination",
        text: "patientMedicineExamination",
        path: "/frekvencia-vysetreni-pacienta/:idPatient/:idMedicine",
        hide: true,
      },
      detail: {
        id: "clientID",
        text: "clientID",
        path: "/pacient/:id",
        hide: true,
      },
      add_notification: {
        id: "addnotification",
        text: "notification",
        path: "/pridat-notifikaciu/:id",
        hide: true,
      },
      edit_notification: {
        id: "editnotification",
        text: "notification",
        path: "/notifikacia/:id",
        hide: true,
      },
    },
  },

  patientsAll: {
    id: "dashboard",
    text: "Všetci pacienti",
    path: "/vsetci-pacienti",
    icon: "Dashboard",
    subMenu: null,
    hide: `${process.env.REACT_APP_RUNNING_ENV}` === "production"
  },

  drugs: {
    id: "drugs",
    text: "Lieky",
    path: "/lieky",
    icon: "PeopleFill",
    subMenu: {
      library: {
        id: "drugs_list",
        text: "Zoznam liekov",
        path: "/lieky",
        icon: "people",
        subMenu: null,
      },
      add: {
        id: "addDrug",
        text: "Pridať liek",
        path: "/lieky/pridat-liek",
        hide: true,
      },
      edit: {
        id: "drugID",
        text: "drugID",
        path: "/liek/:id",
        hide: true,
      },
    },
  },
  examinations_types: {
    id: "examinations_types",
    text: "Typy vyšetrení",
    path: "/typy-vysetreni",
    icon: "PeopleFill",
    subMenu: {
      library: {
        id: "examinations_types_list",
        text: "Zoznam typov vyšetrení",
        path: "/typy-vysetreni",
        icon: "people",
        subMenu: null,
      },
      add: {
        id: "addExaminationType",
        text: "Pridať typ vyšetrenia",
        path: "/typy-vysetreni/pridat-typ-vysetrenia",
        hide: true,
      },
      edit: {
        id: "examinationTypeID",
        text: "examinationTypeID",
        path: "/editovanie-typ-vysetrenia/:id",
        hide: true,
      },
    },
  },
  insurances: {
    id: "insurances",
    text: "Poisťovne",
    path: "/poistovne",
    icon: "PeopleFill",
    subMenu: {
      library: {
        id: "insurances_list",
        text: "Zoznam pacientov",
        path: "/poistovne",
        icon: "people",
        subMenu: null,
      },
      add: {
        id: "addInsurance",
        text: "Pridať poisťovnu",
        path: "/pridat-poistovnu",
        hide: true,
      },
      edit: {
        id: "insuranceID",
        text: "insuranceID",
        path: "/poistovna/:id",
        hide: true,
      },
    },
  },
  personal: {
    id: "personal",
    text: "Personál",
    path: "/personal",
    icon: "personal",
    subMenu: {
      library: {
        id: "personal_list",
        text: "Personál",
        path: "/personal",
        icon: "people",
        subMenu: null,
      },
      add: {
        id: "addPersonal",
        text: "Pridať personal",
        path: "/personal/pridat-personal",
        hide: true,
      },
      edit: {
        id: "personalID",
        text: "personalID",
        path: "/uprava-personal/:id",
        hide: true,
      },
      detail: {
        id: "personaldetailID",
        text: "personaldetailID",
        path: "/personal/:id",
        hide: true,
      },
    },
  },
  workplaces: {
    id: "workplaces",
    text: "Pracoviská",
    path: "/pracoviska",
    subMenu: {
      library: {
        id: "workplace_list",
        text: "Pracoviská",
        path: "/pracoviska",
        icon: "people",
        subMenu: null,
      },
      add: {
        id: "addWorkplace",
        text: "Pridať pracovisko",
        path: "/pracovisko/pridat-pracovisko",
        hide: true,
      },
      edit: {
        id: "workplaceID",
        text: "workplaceID",
        path: "/pracovisko/:id",
        hide: true,
      },
    },
  },
  logs: {
    id: "settings",
    text: "Logy",
    path: "/logy",
    icon: "Settings",
    subMenu: {
      library: {
        id: "logs_list",
        text: "Zoznam logov",
        path: "/logy",
        icon: "people",
        subMenu: null,
      },
      detail: {
        id: "logID",
        text: "logID",
        path: "/log/:id",
        hide: true,
      },
    },
  },
  notifications: {
    id: "notifications",
    text: "Úlohy",
    path: "/ulohy",
    icon: "Notification",
    hide: true,
  },
  settings: {
    id: "settings",
    text: "Nastavenia",
    path: "/nastavenia",
    icon: "Settings",
  },
};

export const publicPage = {
  login: {
    id: "login",
    text: "Login",
    path: "/prihlasenie",
  },
  register: {
    id: "registration",
    text: "Registrácia",
    path: "/registracia",
  },
  lost_password: {
    id: "lost_password",
    text: "Zabudnuté heslo",
    path: "/zabudnute-heslo",
  },
  reset_password: {
    id: "lost_password",
    text: "Zabudnuté heslo",
    path: "/reset-hesla",
  },
  page404: {
    id: "page404",
    text: "Page not found",
    path: "/404",
  },
};
