import React, { lazy } from 'react';
import { adminPage } from '../menu';

const DASHBOARD = {
	HOME: lazy(() => import('pages/dashboard/Dashboard')),
	PATIENTS: lazy(() => import('pages/dashboard/patients/LibraryPage')),
	DETAIL_PATIENT: lazy(() => import('pages/dashboard/patients/EditPage')),
	PATIENT_ADD: lazy(() => import('pages/dashboard/patients/AddPage')),
	PATIENT_EDIT: lazy(() => import('pages/dashboard/patients/EditPage')),
	PATIENT_MEDICINE: lazy(() => import('pages/dashboard/patients/Medicines')),
	PATIENT_MEDICINE_EXAMINATION: lazy(() => import('pages/dashboard/patients/MedicineExaminations')),
	PATIENT_DETAIL: lazy(() => import('pages/dashboard/patients/DetailPage')),
	PATIENTS_ALL: lazy(() => import('pages/dashboard/patients/AllPatients')),
	DRUGS: lazy(() => import('pages/dashboard/drugs/LibraryPage')),
	DRUGS_ADD: lazy(() => import('pages/dashboard/drugs/AddPage')),
	DRUGS_EDIT: lazy(() => import('pages/dashboard/drugs/EditPage')),
	INSURANCES: lazy(() => import('pages/dashboard/insurances/LibraryPage')),
	INSURANCES_ADD: lazy(() => import('pages/dashboard/insurances/AddPage')),
	INSURANCES_EDIT: lazy(() => import('pages/dashboard/insurances/EditPage')),
	NOTIFICATIONS: lazy(() => import('pages/dashboard/tasks/LibraryPage')),
	SETTINS: lazy(() => import('pages/dashboard/Settings')),
	PERSONAL: lazy(() => import('pages/dashboard/personal/LibraryPage')),
	PERSONAL_ADD: lazy(() => import('pages/dashboard/personal/AddPage')),
	PERSONAL_EDIT: lazy(() => import('pages/dashboard/personal/EditPage')),
	PERSONAL_DETAIL: lazy(() => import('pages/dashboard/personal/DetailPage')),
	WORKPLACE: lazy(() => import('pages/dashboard/workplaces/LibraryPage')),
	WORKPLACE_ADD: lazy(() => import('pages/dashboard/workplaces/AddPage')),
	WORKPLACE_EDIT: lazy(() => import('pages/dashboard/workplaces/EditPage')),
	LOGS: lazy(() => import('pages/dashboard/logs/LibraryPage')),
	LOGS_DETAIL: lazy(() => import('pages/dashboard/logs/DetailPage')),
	ADD_NOTIFICATION: lazy(() => import('pages/dashboard/patients/AddNotification')),
	EXAMINATIONTYPES: lazy(() => import('pages/dashboard/examinationTypes/LibraryPage')),
	EXAMINATIONTYPES_ADD: lazy(() => import('pages/dashboard/examinationTypes/AddPage')),
	EXAMINATIONTYPES_EDIT: lazy(() => import('pages/dashboard/examinationTypes/EditPage')),
	// EDIT_PATIENT: lazy(() => import('../pages/dashboard/exercises/AddExercise')),
	
};


const adminPages = [
	/**
	 * Landing
	 */
	 {
		path: "/",
		element: <DASHBOARD.HOME />,
		exact: true,
		title: adminPage.dashboard.title,
		allowed: true
	},
	{
		path: adminPage.patients.path,
		element: <DASHBOARD.PATIENTS />,
		exact: true,
		allowed: true
	},
	{
		path: adminPage.patients.subMenu.add.path,
		element: <DASHBOARD.PATIENT_ADD />,
		exact: true,
		allowed: true
	},
	{
		path: adminPage.patients.subMenu.edit.path,
		element: <DASHBOARD.PATIENT_EDIT />,
		exact: true,
		allowed: true
	},
	{
		path: adminPage.patients.subMenu.patientMedicine.path,
		element: <DASHBOARD.PATIENT_MEDICINE />,
		exact: true,
		allowed: true
	},
	{
		path: adminPage.patients.subMenu.patientMedicineExamination.path,
		element: <DASHBOARD.PATIENT_MEDICINE_EXAMINATION />,
		exact: true,
		allowed: true
	},
	{
		path: adminPage.patients.subMenu.detail.path,
		element: <DASHBOARD.PATIENT_DETAIL />,
		exact: true,
		allowed: true
	},
	{
		path: adminPage.patientsAll.path,
		element: <DASHBOARD.PATIENTS_ALL />,
		exact: true,
		allowed: `${process.env.REACT_APP_RUNNING_ENV}` !== "production"
	},
	{
		path: adminPage.drugs.path,
		element: <DASHBOARD.DRUGS />,
		exact: true,
		allowed: true
	},
	{
		path: adminPage.drugs.subMenu.add.path,
		element: <DASHBOARD.DRUGS_ADD />,
		exact: true,
		allowed: true
	},
	{
		path: adminPage.drugs.subMenu.edit.path,
		element: <DASHBOARD.DRUGS_EDIT />,
		exact: true,
		allowed: true
	},
	{
		path: adminPage.insurances.path,
		element: <DASHBOARD.INSURANCES />,
		exact: true,
		allowed: true
	},
	{
		path: adminPage.insurances.subMenu.add.path,
		element: <DASHBOARD.INSURANCES_ADD />,
		exact: true,
		allowed: true
	},
	{
		path: adminPage.insurances.subMenu.edit.path,
		element: <DASHBOARD.INSURANCES_EDIT />,
		exact: true,
		allowed: true
	},
	{
		path: adminPage.notifications.path,
		element: <DASHBOARD.NOTIFICATIONS />,
		exact: true,
		allowed: true
	},
	{
		path: adminPage.settings.path,
		element: <DASHBOARD.SETTINS />,
		exact: true,
		allowed: true
	},
	{
		path: adminPage.personal.subMenu.library.path,
		element: <DASHBOARD.PERSONAL />,
		exact: true,
		allowed: true
	},
	{
		path: adminPage.personal.subMenu.add.path,
		element: <DASHBOARD.PERSONAL_ADD />,
		exact: true,
		allowed: true
	},
	{
		path: adminPage.personal.subMenu.edit.path,
		element: <DASHBOARD.PERSONAL_EDIT />,
		exact: true,
		allowed: true
	},
	{
		path: adminPage.personal.subMenu.detail.path,
		element: <DASHBOARD.PERSONAL_DETAIL />,
		exact: true,
		allowed: true
	},
	{
		path: adminPage.workplaces.subMenu.library.path,
		element: <DASHBOARD.WORKPLACE />,
		exact: true,
		allowed: true
	},
	{
		path: adminPage.workplaces.subMenu.add.path,
		element: <DASHBOARD.WORKPLACE_ADD />,
		exact: true,
		allowed: true
	},
	{
		path: adminPage.workplaces.subMenu.edit.path,
		element: <DASHBOARD.WORKPLACE_EDIT />,
		exact: true,
		allowed: true
	},
	{
		path: adminPage.logs.subMenu.library.path,
		element: <DASHBOARD.LOGS />,
		exact: true,
		allowed: true
	},
	{
		path: adminPage.logs.subMenu.detail.path,
		element: <DASHBOARD.LOGS_DETAIL />,
		exact: true,
		allowed: true
	},
	{
		path: adminPage.patients.subMenu.add_notification.path,
		element: <DASHBOARD.ADD_NOTIFICATION />,
		exact: true,
		allowed: true
	},
	{
		path: adminPage.examinations_types.subMenu.library.path,
		element: <DASHBOARD.EXAMINATIONTYPES />,
		exact: true,
		allowed: true
	},
	{
		path: adminPage.examinations_types.subMenu.add.path,
		element: <DASHBOARD.EXAMINATIONTYPES_ADD />,
		exact: true,
		allowed: true
	},
	{
		path: adminPage.examinations_types.subMenu.edit.path,
		element: <DASHBOARD.EXAMINATIONTYPES_EDIT />,
		exact: true,
		allowed: true
	},
	// {
	// 	path: protectedPage.drugs.path
	// 	element: <DASHBOARD.DRUGS />,
	// 	exact: true,
	// },

];
const adminContent = [...adminPages];

export default adminContent;
