import React, { useContext } from "react";
import PropTypes from "prop-types";
import Content from "layouts/Content/Content";
import HeaderRoutes from "components/Headers/HeaderRoutes";
import ThemeContext from "contexts/themeContext";
import SidebarRoutes from "components/Sidebar/SidebarRoutes";

export const WrapperContainer = ({ children, className, ...props }) => {
  return (
    <div
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </div>
  );
};

const Wrapper = () => {
  return (
    <WrapperContainer>
      <HeaderRoutes />
      <SidebarRoutes />
      <Content />
    </WrapperContainer>
  );
};

export default Wrapper;
