import React, { useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import { publicPage, protectedPage, adminPage } from "../menu";
import { AuthProvider } from "../contexts/authContext";

import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import useAuth from "../hooks/useAuth";
import { ToastContainer } from "react-toastify";

import Wrapper from "layouts/Wrapper/Wrapper";

import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css";
import { TitleContext } from "contexts/titleContext";
import moment from "moment";
import "moment/locale/sk";
import IdleTimer from "helpers/IdleTimer";
import jwtDecode from "jwt-decode";

moment.locale("sk");

const Protected = ({ children }) => {
  const auth = useAuth();
  let timer = null;
  if (auth.user === null) {
    return <Navigate to="/prihlasenie" replace />;
  }
  const [isTimeout, setIsTimeout] = useState(false);
  useEffect(() => {
    const configVersion = process.env.REACT_APP_RUNNING_VERSION;
    console.log({configVersion})

    if (configVersion !== undefined) {
      const version = localStorage.getItem('version');
      console.log({version})
      if (version) {
        if (Number(configVersion) !== Number(version)) {
          localStorage.setItem("version", configVersion);
          window.location.reload();
        }
      } else {
        localStorage.setItem("version", configVersion);
        window.location.reload();
      }
    }

    timer = new IdleTimer({
      timeout: 7200, //expire after 2 hours
      onTimeout: () => {
        console.log('PROTECTED onTimeout')
        setIsTimeout(true);
        if (auth?.user) {
          auth.signout();
          if(timer){
            timer?.cleanUp();
          }
        }
      },
      onExpired: () => {
        console.log('PROTECTED onExpired')
        // do something if expired on load
        setIsTimeout(true);
        if (auth?.user) {
          auth.signout();
          if(timer){
            timer?.cleanUp();
          }
        }
      },
    });

    return () => {
      //timer.cleanUp();
    };
  }, []);
  return children;
};
const AdminProtected = ({ children }) => {
	const auth = useAuth();
  let timer = null;
	if (auth.user === null) {
	  return <Navigate to="/prihlasenie" replace />;
	} else if(auth?.user && jwtDecode(auth.user.jwt).role !== "admin") {
	  return <Navigate to="/" replace />;
	}
	const [isTimeout, setIsTimeout] = useState(false);
  useEffect(() => {
    timer = new IdleTimer({
      timeout: 7200, //expire after 2 hours
      onTimeout: () => {
        console.log('ADMIN onTimeout')
        setIsTimeout(true);
        if (auth?.user) {
          auth.signout();
          if(timer){
            timer?.cleanUp();
          }
        }
      },
      onExpired: () => {
        console.log('ADMIN onExpired')
        // do something if expired on load
        setIsTimeout(true);
        if (auth?.user) {
          auth.signout();
          if(timer){
            timer?.cleanUp();
          }
        }
      },
    });

    return () => {
      //timer.cleanUp();
    };
  }, []);
  return children;
};

const Logged = ({ children }) => {
  const auth = useAuth();
  if (auth.user === null) {
    return <></>;
  }
  return <Navigate to="/" replace />;
};

const App = () => {
  const auth = useAuth();

  const [title, setTitle] = useState("eHealth");

  let protectedPages = [];
  let adminPages = [];
  let publicPages = [];
  for (let key in protectedPage) {
    if (protectedPage.hasOwnProperty(key)) {
      protectedPages.push(protectedPage[key].path);
      if (protectedPage[key].subMenu !== null) {
        for (let subKey in protectedPage[key].subMenu) {
          protectedPages.push(protectedPage[key].subMenu[subKey].path);
        }
      }
    }
  }
  for (let key in adminPage) {
    if (adminPage.hasOwnProperty(key)) {
      adminPages.push(adminPage[key].path);
      if (adminPage[key].subMenu !== null) {
        for (let subKey in adminPage[key].subMenu) {
          adminPages.push(adminPage[key].subMenu[subKey].path);
        }
      }
    }
  }
  for (let key in publicPage) {
    if (publicPage.hasOwnProperty(key)) {
      publicPages.push(publicPage[key].path);
      if (publicPage[key].subMenu !== null) {
        for (let subKey in publicPage[key].subMenu) {
          protectedPages.push(publicPage[key].subMenu[subKey].path);
        }
      }
    }
  }

  const queryClient = new QueryClient();

  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <TitleContext.Provider value={[title, setTitle]}>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Routes>
            {publicPages.map((path) => (
              <Route key={path} path={path} element={<Logged />} />
            ))}

            {protectedPages.map((path) => (
              <Route
                key={path}
                path={path}
                element={
                  <Protected>
                    <></>
                  </Protected>
                }
              />
            ))}

            {adminPages.map((path) => (
              <Route
                key={path}
                path={path}
                element={
                  <AdminProtected>
                    <></>
                  </AdminProtected>
                }
              />
            ))}
          </Routes>
          <Wrapper />
          <ReactQueryDevtools initialIsOpen />
        </TitleContext.Provider>
      </QueryClientProvider>
    </AuthProvider>
  );
};

export default App;
