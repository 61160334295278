import React, { lazy, useEffect } from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import privateContent from "routes/privateRoutes";
import adminContent from "routes/adminRoutes";
import publicContent from "routes/publicRoutes";
import HeaderStats from "components/Headers/HeaderStats";
import jwtDecode from "jwt-decode";

const PAGE_404 = lazy(() => import("pages/Page404"));

const AdminAuth = ({ children }) => {
  const auth = useAuth();
  if (auth.user === null) {
    return <Navigate to="/prihlasenie" replace />;
  }
  if (jwtDecode(auth.user.jwt).role === "admin") {
    return children;
  }
  return <Navigate to="/prihlasenie" replace />;
};

const DoctorAuth = ({ children }) => {
  const auth = useAuth();
  if (auth.user === null) {
    return <Navigate to="/prihlasenie" replace />;
  }
  if (auth.user) {
    return (
      <>
        <div className="-mt-10">{children}</div>
      </>
    );
  }
};

Array.prototype.contains = function(obj) {
    return this.indexOf(obj) > -1;
};

const ContentRoutes = () => {
  const auth = useAuth();

  useEffect(() => {
    document.title = "eHealth";
  }, []);


  return (
    <Routes>
      {auth?.user && jwtDecode(auth?.user?.jwt).role === "admin" && adminContent.map((page) => {
        if (page.allowed) {
          return (
            <Route
              key={page.path}
              path={page.path}
              element={<AdminAuth>{page.element}</AdminAuth>}
            />
          )
        } else {
          return null
        }
      })}
		  {auth?.user && ['doctor', 'nurse'].contains(jwtDecode(auth?.user?.jwt).role) && privateContent.map((page) => (
            <Route
              key={page.path}
              path={page.path}
              title={page?.title}
              element={<DoctorAuth>{page.element}</DoctorAuth>}
            />
          ))}

      {publicContent.map((page) => (
        <Route key={page.path} path={page.path} element={page.element} />
      ))}

      <Route path="*" element={<PAGE_404 />} />
    </Routes>
  );
};

export default ContentRoutes;
