import useAuth from "hooks/useAuth";
import { Link, useNavigate } from "react-router-dom";
import { Menu, Popover } from "@headlessui/react";

const UserDropdown = () => {
  const auth = useAuth();
  let navigate = useNavigate();

  return (
    <>
      <Menu as="div" className="relative">
        <Menu.Button className="focus:outline-none relative">
          <div className="items-center flex">
            <span className="w-12 h-12 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full">
              <img
                alt="..."
                className="w-12 h-12 object-cover rounded-full align-middle border-none shadow-lg"
                src="https://images.unsplash.com/photo-1528892952291-009c663ce843?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=344&q=80%20344w"
              />
            </span>
          </div>
        </Menu.Button>
        <Menu.Items
          className={
            "absolute right-0 bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
          }
        >
          <Menu.Item>
            <Link to={"/nastavenia"}>
              <div
                className={
                  "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
                }
              >
                Nastavenia
              </div>
            </Link>
          </Menu.Item>
          <div className="h-0 my-2 border border-solid border-blueGray-100" />
		  <Menu.Item>
          <div
            className={
              "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 cursor-pointer"
            }
            onClick={() => {
              auth.signout(() => navigate("/prihlasenie"));
            }}
          >
            Odhlásiť sa
          </div>
		  </Menu.Item>
        </Menu.Items>
      </Menu>
    </>
  );
};

export default UserDropdown;
