import React, { useContext, useEffect, useState } from "react";

import UserDropdown from "components/Dropdowns/UserDropdown.js";
import useAuth from "hooks/useAuth";
import { useLocation } from "react-router-dom";
import useDocumentTitle from "hooks/useDocumentTitle";
import { TitleContext } from "contexts/titleContext";

export default function Navbar() {
	const auth = useAuth()
	let location = useLocation();
	const [title] = useContext(TitleContext);

	const { pageTitle } = useDocumentTitle();
	
  return (
    <>
      {/* Navbar */}
      <nav className="bg-lightBlue-600 md:pt-5 md:pb-20 md:pl-60 top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center p-4">
        <div className="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4">
          {/* Brand */}
      
          {/* Form */}
          <div className="md:flex hidden flex-row flex-wrap items-center lg:ml-auto mr-3 text-white">
		  {auth?.user?.username}
          </div>
          {/* User */}
          <ul className="flex-col md:flex-row list-none items-center hidden md:flex">
            <UserDropdown />
          </ul>
        </div>
      </nav>
      {/* End Navbar */}
    </>
  );
}
