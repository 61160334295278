import React, { lazy } from 'react';
import { publicPage } from '../menu';


const AUTH = {
	PAGE_404: lazy(() => import('pages/Page404')),
	LOGIN: lazy(() => import('pages/auth/Login')),
	REGISTER: lazy(() => import('pages/auth/Register')),
	LOST_PASSWORD: lazy(() => import('pages/auth/LostPassword')),
	RESET_PASSWORD: lazy(() => import('pages/auth/ResetPassword')),
	

};


const publicPages = [
	{
		path: publicPage.page404.path,
		element: <AUTH.PAGE_404 />,
		exact: true,
	},
	{
		path: publicPage.login.path,
		element: <AUTH.LOGIN />,
		exact: true,
	},
	{
		path: publicPage.lost_password.path,
		element: <AUTH.LOST_PASSWORD />,
		exact: true,
	},
	{
		path: publicPage.reset_password.path,
		element: <AUTH.RESET_PASSWORD />,
		exact: true,
	},
	// {
	// 	path: demoPages.signUp.path,
	// 	element: <AUTH.REGISTER />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.lostPassword.path,
	// 	element: <AUTH.LOST_PASSWORD />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.resetPassword.path,
	// 	element: <AUTH.RESET_PASSWORD />,
	// 	exact: true,
	//}
]

const publicContent = [...publicPages];

export default publicContent;
